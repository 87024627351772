var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticStyle:{"padding-top":"0"},attrs:{"grid-list-xl":"","fluid":""}},[_c('v-layout',{attrs:{"wrap":""}},[_c('v-flex',{attrs:{"md12":""}},[_c('material-card',{attrs:{"title":_vm.$t('issueVoucherRequest.requestListReceived'),"color":"green","flat":"","full-width":""}},[_c('v-data-table',{attrs:{"no-data-text":_vm.$t('common.noDataAvailable'),"headers":_vm.issueVoucherRequestHeaders,"items":_vm.issueEVoucherReceiveList,"hide-actions":""},scopedSlots:_vm._u([{key:"headerCell",fn:function(ref){
var header = ref.header;
return [_c('span',{staticClass:"text--darken-3 font-medium",domProps:{"textContent":_vm._s(_vm.$t(header.text))}})]}},{key:"items",fn:function(props){return [_c('td',{staticClass:"text-xs-center"},[_vm._v(_vm._s(props.item.stt))]),_c('td',{staticClass:"text-xs-center"},[_vm._v(_vm._s(props.item.dateCreated))]),_c('td',{staticClass:"text-xs-center"},[_vm._v(_vm._s(props.item.name))]),_c('td',{staticClass:"text-xs-center"},[_vm._v(_vm._s(props.item.workpiece.name))]),_c('td',{staticClass:"text-xs-center"},[_vm._v(_vm._s(props.item.quantity))]),_c('td',{staticClass:"text-xs-center",staticStyle:{"white-space":"pre"}},[_vm._v(_vm._s(props.item.note))]),_c('td',{staticClass:"text-xs-center"},[_vm._v(_vm._s(_vm.$t(_vm.getStatusText(props.item.status))))]),(props.item.status === _vm.VoucherRequestStatus.INITIAL)?_c('td',{staticClass:"text-xs-center",staticStyle:{"white-space":"nowrap"}},[(_vm.hasAction('request_approval'))?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mx-2",staticStyle:{"color":"#ffffff"},attrs:{"loading":props.item.isLoadingApprove,"fab":"","small":"","color":"primary"},on:{"click":function($event){return _vm.onShowModalConfirm($event, props.item, _vm.VoucherRequestActionType.APPROVE)}}},on),[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-check")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('issueVoucherRequest.approve')))])]):_vm._e(),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mx-2",staticStyle:{"color":"#ffffff"},attrs:{"loading":props.item.isLoadingCancel,"fab":"","small":"","color":"red"},on:{"click":function($event){return _vm.onShowModalConfirm($event, props.item, _vm.VoucherRequestActionType.CANCEL)}}},on),[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-close")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('issueVoucherRequest.cancel')))])])],1):_vm._e()]}}])})],1),_c('pagination',{attrs:{"total":_vm.receivePaginate.totalPage,"current-page":_vm.receivePaginate.currentPage,"row-per-page":_vm.receivePaginate.rowPerPage},on:{"onPageChange":_vm.onReceivePageChange}}),_c('material-card',{attrs:{"title":_vm.$t('issueVoucherRequest.listOfRequestSent'),"color":"green","flat":"","full-width":""}},[_c('v-data-table',{attrs:{"no-data-text":_vm.$t('common.noDataAvailable'),"headers":_vm.issueVoucherRequestHeaders,"items":_vm.issueVoucherRequestList,"hide-actions":""},scopedSlots:_vm._u([{key:"headerCell",fn:function(ref){
var header = ref.header;
return [_c('span',{staticClass:"text--darken-3 font-medium",domProps:{"textContent":_vm._s(_vm.$t(header.text))}})]}},{key:"items",fn:function(props){return [_c('td',{staticClass:"text-xs-center"},[_vm._v(_vm._s(props.item.stt))]),_c('td',{staticClass:"text-xs-center"},[_vm._v(_vm._s(props.item.dateCreated))]),_c('td',{staticClass:"text-xs-center"},[_vm._v(_vm._s(props.item.name))]),_c('td',{staticClass:"text-xs-center"},[_vm._v(_vm._s(props.item.workpiece.name))]),_c('td',{staticClass:"text-xs-center"},[_vm._v(_vm._s(props.item.quantity))]),_c('td',{staticClass:"text-xs-center",staticStyle:{"white-space":"pre"}},[_vm._v(_vm._s(props.item.note))]),_c('td',{staticClass:"text-xs-center"},[_vm._v(_vm._s(_vm.$t(_vm.getStatusText(props.item.status))))]),(props.item.status === _vm.VoucherRequestStatus.INITIAL)?_c('td',{staticClass:"text-xs-center",staticStyle:{"white-space":"nowrap"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mx-2",staticStyle:{"color":"#ffffff"},attrs:{"loading":props.item.isLoadingStop,"fab":"","small":"","color":"warning"},on:{"click":function($event){return _vm.onShowModalConfirm($event, props.item, _vm.VoucherRequestActionType.STOP)}}},on),[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-stop")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s('Stop'))])]),(false)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mx-2",staticStyle:{"color":"#ffffff"},attrs:{"fab":"","small":"","color":"primary"},on:{"click":function($event){return _vm.onShowModalUpdateIssueEVoucherRequest($event, props.item)}}},on),[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-square-edit-outline")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('common.update')))])]):_vm._e(),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mx-2",staticStyle:{"color":"#ffffff"},attrs:{"fab":"","small":"","color":"red"},on:{"click":function($event){return _vm.onShowModalConfirm($event, props.item, _vm.VoucherRequestActionType.DELETE)}}},on),[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-delete")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('common.delete')))])])],1):_vm._e()]}}])})],1),_c('pagination',{attrs:{"total":_vm.requestPaginate.totalPage,"current-page":_vm.requestPaginate.currentPage,"row-per-page":_vm.requestPaginate.rowPerPage},on:{"onPageChange":_vm.onRequestPageChange}})],1)],1),_vm._l((_vm.GET_SNACK_BAR),function(snackbar){return _c('snack-bar',{key:snackbar.id,attrs:{"item":snackbar}})}),_c('updateIssueEVoucherRequestModal',{ref:"updateIssueEVoucherRequest",on:{"updateSuccess":_vm.onUpdateSuccess}}),_c('confirmModal',{ref:"confirmModal",attrs:{"title":_vm.confirmModalTitle},on:{"onConfirm":_vm.onConfirmEVoucherRequest}}),_c('addEVoucherList',{ref:"addEVoucherList",on:{"createEVoucherSuccess":function($event){return _vm.onCreateEVoucherSuccess()}}}),_c('assignVoucherModal',{ref:"assignVoucherModal",on:{"transactionVoucherSuccess":function($event){return _vm.onTransactionVoucherSuccess()},"onCreateEVoucher":_vm.onShowModalAddListEvoucher}}),_c('loading-bar',{attrs:{"is-loading":_vm.isLoading}})],2)}
var staticRenderFns = []

export { render, staticRenderFns }