<template>
  <v-dialog
    v-model="isShowModal"
    persistent
    max-width="600px">
    <v-card>
      <material-card
        :title="$t('issueVoucherRequest.updateRequestTitle')"
        tile
        color="green"
        full-width
      >
        <v-container grid-list-xl>
          <v-form
            ref="form"
            lazy-validation>
            <v-layout
              wrap
              layout>
              <v-flex
                xs12
                sm6
                md6
              >
                <v-text-field
                  v-model="title"
                  :label="$t('issueVoucherRequest.title')"
                />
              </v-flex>
              <v-flex
                xs12
                sm6
                md6
              >
                <v-text-field
                  v-model="quantity"
                  :label="$t('issueVoucherRequest.quantity')"
                  type="number"
                />
              </v-flex>
              <v-flex
                xs12
                sm6
                md6
              >
                <v-textarea
                  v-model="note"
                  :label="$t('common.note')"
                />
              </v-flex>
            </v-layout>
          </v-form>
        </v-container>
      </material-card>
      <v-card-actions>
        <v-spacer />
        <v-btn
          small
          color="error darken-1"
          @click="onCloseModal"
        >{{ $t("common.close") }}
        </v-btn>
        <v-btn
          :loading="isLoading"
          small
          color="success darken-1"
          @click="onConfirm"
        >{{ $t("common.confirm") }}
        </v-btn>
      </v-card-actions>
    </v-card>
    <snack-bar
      v-for="snackbar in GET_SNACK_BAR"
      :key="snackbar.id"
      :item="snackbar"
    />
  </v-dialog>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import ToastType from 'enum/toastType'
export default {
  data: () => ({
    isLoading: false,
    isShowModal: false,
    id: null,
    title: null,
    quantity: null,
    note: null
  }),
  computed: {
    ...mapGetters([
      'GET_SNACK_BAR'
    ])
  },
  methods: {
    /**
     * Confirm modal
     */
    onConfirm: function () {
      let filter = {
        name: this.title,
        quantity: this.quantity,
        description: this.note
      }
      this.isLoading = true
      this.UPDATE_ISSUE_VOUCHER_REQUEST({ id: this.id, ...filter }).then(
        function () {
          this.isLoading = false
          this.isShowModal = false
          this.$emit('updateSuccess')
          this.ON_SHOW_TOAST({
            message: this.$t('common.success'),
            styleType: ToastType.SUCCESS
          })
        }.bind(this)
      ).catch(
        function () {
          this.isLoading = false
          this.ON_SHOW_TOAST({
            message: this.$t('login.somethingWentWrong'),
            styleType: ToastType.ERROR
          })
        }.bind(this)
      )
    },
    /**
     * Close modal
     */
    onCloseModal: function () {
      this.isLoading = false
      this.isShowModal = false
    },
    /**
     * Show modal
     */
    onShowModal: function (data) {
      this.id = data.id
      this.title = data.name
      this.quantity = data.quantity
      this.note = data.note
      this.isShowModal = true
    },
    ...mapActions([
      'ON_SHOW_TOAST',
      'UPDATE_ISSUE_VOUCHER_REQUEST'
    ])
  }
}
</script>

<style lang="scss" scoped>
.modal {
  box-shadow: 2px 2px 20px 1px;
  overflow-x: auto;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}
.v-card {
  margin-bottom: 0px !important;
  margin-top: 21px !important;
}
.container.grid-list-xl .layout .flex {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
/deep/.required label::after {
  content: " *";
  color: red;
}
</style>
