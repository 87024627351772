<template>
  <v-container
    grid-list-xl
    fluid
    style="padding-top: 0;">
    <v-layout wrap>
      <v-flex md12>
        <!-- Receive list -->
        <material-card
          :title="$t('issueVoucherRequest.requestListReceived')"
          color="green"
          flat
          full-width
        >
          <v-data-table
            :no-data-text="$t('common.noDataAvailable')"
            :headers="issueVoucherRequestHeaders"
            :items="issueEVoucherReceiveList"
            hide-actions
          >
            <template
              slot="headerCell"
              slot-scope="{ header }">
              <span
                class="text--darken-3 font-medium"
                v-text="$t(header.text)"
              />
            </template>
            <template v-slot:items="props">
              <td class="text-xs-center">{{ props.item.stt }}</td>
              <td class="text-xs-center">{{ props.item.dateCreated }}</td>
              <td class="text-xs-center">{{ props.item.name }}</td>
              <td class="text-xs-center">{{ props.item.workpiece.name }}</td>
              <td class="text-xs-center">{{ props.item.quantity }}</td>
              <td
                class="text-xs-center"
                style="white-space: pre;">{{ props.item.note }}</td>
              <td class="text-xs-center">{{ $t(getStatusText(props.item.status)) }}</td>
              <td
                v-if="props.item.status === VoucherRequestStatus.INITIAL"
                class="text-xs-center"
                style="white-space: nowrap;">
                <v-tooltip
                  v-if="hasAction('request_approval')"
                  top>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      :loading="props.item.isLoadingApprove"
                      class="mx-2"
                      fab
                      small
                      color="primary"
                      style="color: #ffffff;"
                      v-on="on"
                      @click="onShowModalConfirm($event, props.item, VoucherRequestActionType.APPROVE)"
                    >
                      <v-icon dark>mdi-check</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t('issueVoucherRequest.approve') }}</span>
                </v-tooltip>
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      :loading="props.item.isLoadingCancel"
                      class="mx-2"
                      fab
                      small
                      color="red"
                      style="color: #ffffff;"
                      v-on="on"
                      @click="onShowModalConfirm($event, props.item, VoucherRequestActionType.CANCEL)"
                    >
                      <v-icon dark>mdi-close</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t('issueVoucherRequest.cancel') }}</span>
                </v-tooltip>
              </td>
            </template>
          </v-data-table>
        </material-card>
        <pagination
          :total="receivePaginate.totalPage"
          :current-page="receivePaginate.currentPage"
          :row-per-page="receivePaginate.rowPerPage"
          @onPageChange="onReceivePageChange"
        />
        <!-- Request list -->
        <material-card
          :title="$t('issueVoucherRequest.listOfRequestSent')"
          color="green"
          flat
          full-width
        >
          <v-data-table
            :no-data-text="$t('common.noDataAvailable')"
            :headers="issueVoucherRequestHeaders"
            :items="issueVoucherRequestList"
            hide-actions
          >
            <template
              slot="headerCell"
              slot-scope="{ header }">
              <span
                class="text--darken-3 font-medium"
                v-text="$t(header.text)"
              />
            </template>
            <template v-slot:items="props">
              <td class="text-xs-center">{{ props.item.stt }}</td>
              <td class="text-xs-center">{{ props.item.dateCreated }}</td>
              <td class="text-xs-center">{{ props.item.name }}</td>
              <td class="text-xs-center">{{ props.item.workpiece.name }}</td>
              <td class="text-xs-center">{{ props.item.quantity }}</td>
              <td
                class="text-xs-center"
                style="white-space: pre;">{{ props.item.note }}</td>
              <td class="text-xs-center">{{ $t(getStatusText(props.item.status)) }}</td>
              <td
                v-if="props.item.status === VoucherRequestStatus.INITIAL"
                class="text-xs-center"
                style="white-space: nowrap;">
                <v-tooltip
                  top>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      :loading="props.item.isLoadingStop"
                      class="mx-2"
                      fab
                      small
                      color="warning"
                      style="color: #ffffff;"
                      v-on="on"
                      @click="onShowModalConfirm($event, props.item, VoucherRequestActionType.STOP)"
                    >
                      <v-icon dark>mdi-stop</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ 'Stop' }}</span>
                </v-tooltip>
                <v-tooltip
                  v-if="false"
                  top>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      class="mx-2"
                      fab
                      small
                      color="primary"
                      style="color: #ffffff;"
                      v-on="on"
                      @click="onShowModalUpdateIssueEVoucherRequest($event, props.item)"
                    >
                      <v-icon dark>mdi-square-edit-outline</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t('common.update') }}</span>
                </v-tooltip>
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      class="mx-2"
                      fab
                      small
                      color="red"
                      style="color: #ffffff;"
                      v-on="on"
                      @click="onShowModalConfirm($event, props.item, VoucherRequestActionType.DELETE)"
                    >
                      <v-icon dark>mdi-delete</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t('common.delete') }}</span>
                </v-tooltip>
              </td>
            </template>
          </v-data-table>
        </material-card>
        <pagination
          :total="requestPaginate.totalPage"
          :current-page="requestPaginate.currentPage"
          :row-per-page="requestPaginate.rowPerPage"
          @onPageChange="onRequestPageChange"
        />
      </v-flex>
    </v-layout>
    <snack-bar
      v-for="snackbar in GET_SNACK_BAR"
      :key="snackbar.id"
      :item="snackbar"
    />
    <updateIssueEVoucherRequestModal
      ref="updateIssueEVoucherRequest"
      @updateSuccess="onUpdateSuccess"/>
    <confirmModal
      ref="confirmModal"
      :title="confirmModalTitle"
      @onConfirm="onConfirmEVoucherRequest" />
    <addEVoucherList
      ref="addEVoucherList"
      @createEVoucherSuccess="onCreateEVoucherSuccess()"
    />
    <assignVoucherModal
      ref="assignVoucherModal"
      @transactionVoucherSuccess="onTransactionVoucherSuccess()"
      @onCreateEVoucher="onShowModalAddListEvoucher"
    />
    <loading-bar :is-loading="isLoading" />
  </v-container>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import VoucherRequestType from 'enum/voucherRequestType'
import VoucherRequestStatus from 'enum/voucherRequestStatus'
import UpdateIssueEVoucherRequestModal from './UpdateIssueEVoucherRequestModal'
import ConfirmModal from 'Components/ConfirmModal'
import ToastType from 'enum/toastType'
import VoucherRequestActionType from 'enum/voucherRequestActionType'
import AssignVoucherModal from './AssignVoucherModal'
import AssignFromType from 'enum/assignFromType'
import dateUtils from 'utils/dateUtils'
import functionUtils from 'utils/functionUtils'
import AddEVoucherList from './AddEvoucherList'
export default {
  components: {
    AssignVoucherModal,
    UpdateIssueEVoucherRequestModal,
    ConfirmModal,
    AddEVoucherList
  },
  data: () => ({
    issueVoucherRequestHeaders: [
      {
        sortable: false,
        text: 'common.nonumber',
        value: 'stt',
        align: 'center'
      },
      {
        sortable: false,
        text: 'issueVoucherRequest.dateRequest',
        value: 'dateCreated',
        align: 'center'
      },
      {
        sortable: false,
        text: 'issueVoucherRequest.title',
        value: 'name',
        align: 'center'
      },
      {
        sortable: false,
        text: 'evoucher.issuance.workpiece',
        value: 'voucherName',
        align: 'center'
      },
      {
        sortable: false,
        text: 'issueVoucherRequest.quantity',
        value: 'quantity',
        align: 'center'
      },
      {
        sortable: false,
        text: 'common.note',
        value: 'note',
        align: 'center'
      },
      {
        sortable: false,
        text: 'common.status',
        value: 'status',
        align: 'center'
      },
      {
        sortable: false,
        text: 'common.actions',
        value: 'actions',
        align: 'center'
      }
    ],
    issueVoucherRequestList: [],
    requestPaginate: {
      totalPage: 1,
      currentPage: 1,
      rowPerPage: 1
    },
    issueEVoucherReceiveList: [],
    receivePaginate: {
      totalPage: 1,
      currentPage: 1,
      rowPerPage: 1
    },
    VoucherRequestStatus: VoucherRequestStatus,
    confirmModalTitle: '',
    issueEVoucherRequestId: null,
    VoucherRequestActionType: VoucherRequestActionType,
    isLoading: false,
    eVoucherRequestActionSelected: null,
    eVoucherRequestItem: null
  }),
  computed: {
    ...mapGetters([
      'GET_SNACK_BAR',
      'GET_CURRENT_ACCOUNT'
    ])
  },
  created () {
    this.getIssueEVoucherRequest()
    this.getIssueEVoucherReceive()
  },
  methods: {
    /**
     * Show modal add list e voucher
     */
    onShowModalAddListEvoucher: function (createVoucherType, workpieceId, issuanceId) {
      this.$refs.addEVoucherList.onShowModal(createVoucherType, workpieceId, issuanceId)
    },
    /**
     * Show modal confirm
     */
    onShowModalConfirm: function (event, item, requestActionType) {
      event.preventDefault()
      this.eVoucherRequestItem = item
      this.eVoucherRequestActionSelected = requestActionType
      if (this.eVoucherRequestActionSelected === VoucherRequestActionType.APPROVE) {
        this.confirmModalTitle = this.$t('issueVoucherRequest.approveTitle')
      } else if (this.eVoucherRequestActionSelected === VoucherRequestActionType.CANCEL) {
        this.confirmModalTitle = this.$t('issueVoucherRequest.cancelTitle')
      } else if (this.eVoucherRequestActionSelected === VoucherRequestActionType.DELETE) {
        this.issueEVoucherRequestId = item.id
        this.confirmModalTitle = this.$t('issueVoucherRequest.deleteTitle')
      } else if (this.eVoucherRequestActionSelected === VoucherRequestActionType.STOP) {
        this.confirmModalTitle = this.$t('issueVoucherRequest.stopTitle')
      }
      this.$refs.confirmModal.onShowModal()
    },
    /**
     * Transaction voucher success
     */
    onTransactionVoucherSuccess: function () {
      this.getIssueEVoucherReceive()
    },
    /**
     * Approve issue e voucher request
     */
    onApproveIssueEVoucherRequest: function (item, type) {
      if (type === VoucherRequestActionType.CANCEL) {
        let filter = {
          type: type
        }
        if (type === VoucherRequestActionType.APPROVE) {
          item.isLoadingApprove = true
        } else {
          item.isLoadingCancel = true
        }
        this.APPROVE_CANCEL_ISSUE_VOUCHER_REQUEST({ id: item.id, ...filter }).then(
          function () {
            this.getIssueEVoucherReceive()
            item.isLoadingApprove = false
            item.isLoadingCancel = false
            this.ON_SHOW_TOAST({
              message: this.$t('common.success'),
              styleType: ToastType.SUCCESS
            })
          }.bind(this)
        )
      } else {
        let assignFromRequestSentData = {
          fromEntity: item.fromEntity,
          quantity: item.quantity,
          requestId: item.id
        }
        this.$refs.assignVoucherModal.onShowModal([], item.workpiece.id, AssignFromType.FROM_REQUEST_SENT, assignFromRequestSentData)
      }
    },
    /**
     * Confirm delete issue e voucher request
     */
    onConfirmEVoucherRequest: function () {
      if (this.eVoucherRequestActionSelected === VoucherRequestActionType.APPROVE) {
        this.onApproveIssueEVoucherRequest(this.eVoucherRequestItem, VoucherRequestActionType.APPROVE)
      } else if (this.eVoucherRequestActionSelected === VoucherRequestActionType.CANCEL) {
        this.onApproveIssueEVoucherRequest(this.eVoucherRequestItem, VoucherRequestActionType.CANCEL)
      } else if (this.eVoucherRequestActionSelected === VoucherRequestActionType.DELETE) {
        this.DELETE_ISSUE_VOUCHER_REQUEST({ id: this.issueEVoucherRequestId }).then(
          function () {
            this.getIssueEVoucherRequest()
            this.ON_SHOW_TOAST({
              message: this.$t('common.success'),
              styleType: ToastType.SUCCESS
            })
          }.bind(this)
        )
      } else if (this.eVoucherRequestActionSelected === VoucherRequestActionType.STOP) {
        this.onStopSendingRequests(this.eVoucherRequestItem)
      }
    },
    /**
     * Update success
     */
    onUpdateSuccess: function () {
      this.getIssueEVoucherRequest()
    },
    /**
     * Show modal update issue e voucher request
     */
    onShowModalUpdateIssueEVoucherRequest: function (item) {
      // event.preventDefault()
      this.$refs.updateIssueEVoucherRequest.onShowModal(item)
    },
    /**
     * On Stop sending requests
     */
    onStopSendingRequests: function (item) {
      let filter = {
        status: VoucherRequestStatus.STOP
      }
      item.isLoadingStop = true
      this.STOP_SENDING_REQUESTS({ id: item.id, ...filter }).then(
        function () {
          item.isLoadingStop = false
          this.ON_SHOW_TOAST({
            message: this.$t('common.success'),
            styleType: ToastType.SUCCESS
          })
          this.getIssueEVoucherRequest()
        }.bind(this)
      ).catch(
        function () {
          this.ON_SHOW_TOAST({
            message: this.$t('login.somethingWentWrong'),
            styleType: ToastType.ERROR
          })
        }.bind(this)
      )
    },
    /**
     * Get status text
     */
    getStatusText: function (status) {
      if (status === VoucherRequestStatus.INITIAL) {
        return 'issueVoucherRequest.initial'
      } else if (status === VoucherRequestStatus.APPROVE) {
        return 'issueVoucherRequest.approved'
      } else if (status === VoucherRequestStatus.CANCEL) {
        return 'issueVoucherRequest.canceled'
      } else if (status === VoucherRequestStatus.STOP) {
        return 'issueVoucherRequest.stop'
      } else {
        return ''
      }
    },
    /**
     * Get issue e voucher receive
     */
    getIssueEVoucherReceive: function () {
      let filter = {
        params: {
          page: this.receivePaginate.currentPage,
          type: VoucherRequestType.RECEIVE
        }
      }
      this.isLoading = true
      this.GET_ISSUE_VOUCHER_REQUEST_BY_TYPE(filter).then(
        function (res) {
          let data = res.data
          this.receivePaginate.currentPage = data.page
          this.receivePaginate.totalPage = data.num_pages
          this.receivePaginate.rowPerPage = data.per_page / 2
          let results = data.results
          this.issueEVoucherReceiveList = []
          for (let i = 0, size = results.length; i < size; i++) {
            let obj = {
              stt: i + 1 + (data.page - 1) * data.page_size,
              id: results[i].id,
              name: results[i].name,
              quantity: results[i].quantity,
              note: results[i].note,
              status: results[i].status,
              isLoadingApprove: false,
              isLoadingCancel: false,
              isLoadingStop: false,
              workpiece: {
                'id': results[i].workpiece && results[i].workpiece.id,
                'name': results[i].workpiece && results[i].workpiece.name
              },
              fromEntity: results[i].from_entity,
              dateCreated: dateUtils.formatBeautyDate(results[i].date_created)
            }
            this.issueEVoucherReceiveList.push(obj)
          }
          this.isLoading = false
        }.bind(this)
      ).catch(
        function () {
          this.isLoading = false
        }.bind(this)
      )
    },
    /**
     * Get issue e voucher request
     */
    getIssueEVoucherRequest: function () {
      let filter = {
        params: {
          page: this.requestPaginate.currentPage,
          type: VoucherRequestType.REQUEST
        }
      }
      this.GET_ISSUE_VOUCHER_REQUEST_BY_TYPE(filter).then(
        function (res) {
          let data = res.data
          this.requestPaginate.currentPage = data.page
          this.requestPaginate.totalPage = data.num_pages
          this.requestPaginate.rowPerPage = data.per_page / 2
          let results = data.results
          this.issueVoucherRequestList = []
          for (let i = 0, size = results.length; i < size; i++) {
            let obj = {
              stt: i + 1 + (data.page - 1) * data.page_size,
              id: results[i].id,
              name: results[i].name,
              quantity: results[i].quantity,
              note: results[i].note,
              status: results[i].status,
              isLoadingApprove: false,
              isLoadingCancel: false,
              isLoadingStop: false,
              workpiece: {
                'id': results[i].workpiece && results[i].workpiece.id,
                'name': results[i].workpiece && results[i].workpiece.name
              },
              dateCreated: dateUtils.formatBeautyDate(results[i].date_created)
            }
            this.issueVoucherRequestList.push(obj)
          }
        }.bind(this)
      )
    },
    /**
     * Page change
     */
    onReceivePageChange: function (page) {
      this.receivePaginate.currentPage = page
      this.getIssueEVoucherReceive()
    },
    /**
     * Page change
     */
    onRequestPageChange: function (page) {
      this.requestPaginate.currentPage = page
      this.getIssueEVoucherRequest()
    },
    /**
     * Check has action
     */
    hasAction: function (metaAction) {
      return functionUtils.hasAction(this.GET_CURRENT_ACCOUNT, metaAction)
    },
    ...mapActions([
      'GET_ISSUE_VOUCHER_REQUEST_BY_TYPE',
      'DELETE_ISSUE_VOUCHER_REQUEST',
      'ON_SHOW_TOAST',
      'APPROVE_CANCEL_ISSUE_VOUCHER_REQUEST',
      'STOP_SENDING_REQUESTS'
    ])
  }
}
</script>
<style lang="scss" scoped></style>
